import pic from './646A9377.jpg';
import './App.css';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <img src={pic} className="App-logo" alt="logo" />
        <h2>
          Pardon our dust!
        </h2>
        <p>
          We are currently working on this site, check back soon for updates!
        </p>
      </header>
    </div>
  );
}

export default App;
